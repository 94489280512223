import { defineComponent, h, TransitionGroup } from 'vue';
import { QIcon } from '@quasar/components';

export default defineComponent({
  name: 'VNotify',
  components: { QIcon },

  data() {
    return {
      items: [],
      maxVisible: 3,
    };
  },

  async mounted() {
    this.handleBackendMessages();

    this.$eventHub.$on('v-notify-top-right', this.addNotify);
  },

  methods: {
    handleBackendMessages() {
      const messages = [];
      const core = window.core_project;
      if (core) {
        const addMessage = (type, message) => {
          messages.push({ type, message });
        };
        const handleType = (type) => {
          if (core[type]) {
            const tmpType = type.slice(0, -1);
            const msgType = tmpType === 'message' ? 'success' : tmpType;
            if (Array.isArray(core[type])) {
              _.forEach(core[type], (itm) => addMessage(msgType, itm));
            } else if (typeof core[type] === 'string') {
              addMessage(msgType, core[type]);
            }
            delete core[type];
          }
        };
        handleType('messages');
        handleType('errors');
        handleType('warns');
        handleType('infos');
        if (messages?.length) {
          messages.forEach((item) => {
            this.$notify({
              type: item.type,
              text: item.message,
            });
          });
        }
      }
    },
    getTitleByType(type) {
      return this.trans(`notice.${type === 'success' ? 'message' : type}`);
    },
    addNotify(item) {
      if (this.items.length >= this.maxVisible) {
        this.removeNotify(0)();
      }

      this.items.push({
        ...item,
        key: (`${Math.random()}`).substr(2, 18),
      });
    },

    removeNotify(index) {
      return () => {
        this.items.splice(index, 1);
      };
    },

    getIcon(type) {
      let name = '';
      switch (type) {
        case 'error': name = 'times-circle'; break;
        case 'warn': name = 'exclamation-triangle'; break;
        case 'info': name = 'exclamation-circle'; break;
        default: name = 'check-circle'; break;
      }

      return `s-str-ico-fa s-str-ico-fa--thm-${name}`;
    },

    createNotify(item, itemIdx) {
      return h('div', {
        key: item.text + item.key,
        class: `sn-notify sn-notify--${item.type}`,
      }, [
        h('button', {
          class: 'sn-notify__close',
          onClick: this.removeNotify(itemIdx),
        }),
        h('div', { class: `sn-notify__title ${this.getIcon(item.type)}` }, item.title ?? this.getTitleByType(item.type)),
        h('div', { class: 'sn-notify__text', innerHTML: item.text }),
      ]);
    },
  },

  render() {
    return h('div', [
      h(TransitionGroup, {
        class: 'sn-notify__wrapper sn-notify__wrapper--pos-top-right',
        name: 'sn-transition--flip-right',
        tag: 'div',
      }, () => this.items.map((item, itemIdx) => this.createNotify(item, itemIdx))),
    ]);
  },
});
